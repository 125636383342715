import React from 'react';
import Layout from '../../components/Layout';
import blogd1 from '../../assets/images/2024/world-patient-safety/1.jpeg';
import blogd2 from '../../assets/images/2024/world-patient-safety/2.jpeg';
import blogd3 from '../../assets/images/2024/world-patient-safety/3.jpeg';
import blogd4 from '../../assets/images/2024/world-patient-safety/4.jpeg';
import blogd5 from '../../assets/images/2024/world-patient-safety/5.jpeg';
import blogd6 from '../../assets/images/2024/world-patient-safety/6.jpeg';
import blogd7 from '../../assets/images/2024/world-patient-safety/7.jpeg';
import blogd8 from '../../assets/images/2024/world-patient-safety/8.jpeg';
import blogd9 from '../../assets/images/2024/world-patient-safety/9.jpeg';
import blogd10 from '../../assets/images/2024/world-patient-safety/10.jpeg';
import NavOne from '../../components/NavOne';
import PageHeader from '../../components/PageHeader';
import Footer from '../../components/Footer';

const NewsDetails = () => {
  return (
    <section className='blog-details'>
      <div className='container'>
        <div className='row'>
          <div className='col-lg-12'>
            <div className='blog-one__single'>
              <div className='blog-one__image'>
                <img src={blogd1} alt='' />
              </div>
              <div className='blog-one__content text-center'>
                <h2 className='blog-one__title'>
                  LAGOS UNIVERSITY TEACHING HOSPITAL (LUTH) CELEBRATES WORLD
                  PATIENT SAFETY DAY 2024
                </h2>
                <p className='blog-one__text' style={{ marginBottom: 30 }}>
                  Lagos University Teaching Hospital (LUTH), Idi-Araba, joined
                  the rest of the world to commemorate World Patient Safety Day
                  2024, under the theme “Improving Diagnosis for Patient Safety:
                  Get it Right, Make it Safe.” The event highlighted the
                  importance of accurate diagnosis as the foundation of safe and
                  effective patient care.
                </p>
                <div className='row'>
                  <div className='col-lg-6'>
                    <img src={blogd2} height={370} />
                  </div>
                  <div className='col-lg-6'>
                    <img src={blogd3} height={370} />
                  </div>
                </div>
                <span style={{ fontSize: 14, fontStyle: 'italic' }}>
                  Consultative Services Ongoing at the Outreach
                </span>
                <p className='blog-one__text' style={{ marginBottom: 30 }}>
                  Speaking at the event, the Chief Medical Director, Professor
                  Wasiu Lanre Adeyemo (FAS), emphasized the institution's
                  commitment to patient safety. He noted: "When we talk about
                  patient safety, it encompasses everything from diagnosis to
                  treatment. This year’s theme focuses on the accuracy of
                  diagnosis. Getting the diagnosis right is critical to ensuring
                  the safety of our patients. Patient safety is not the sole
                  responsibility of clinical staff—everyone in the Hospital
                  plays a role, from Doctors and Nurses to Pharmacists,
                  Cleaners, and Security Officers." Professor Adeyemo also
                  highlighted LUTH’s efforts to enhance patient safety through
                  the Hospital’s Quality Improvement Committee, which organizes
                  continuous training for both clinical and non-clinical staff.
                  He further reiterated the need for staff presence and
                  attentiveness in ensuring the delivery of safe, high-quality
                  care.
                  <br />
                  <br />
                  Dr. Ayesha Akinkungbe, a Dermatologist and the Nodal Officer
                  for SERVICOM at LUTH, stressed the importance of
                  accountability and continuous self-improvement among
                  healthcare workers
                  <br />
                  Dr. Akinkungbe stated that "Everyone must understand the role
                  they play in patient safety, from history taking to diagnostic
                  procedures. Communication is the bedrock of everything. Every
                  healthcare worker should hold themselves accountable and seek
                  ways to improve, even in small units. Additionally, we should
                  all strive to be computer literate as we move towards fully
                  electronic medical records."
                  <br />
                  <br />
                  Also speaking at the event, Mrs. Fasilat Olujumoke Akinola,
                  Head, Nursing Services (Hospital Services), emphasized the
                  importance of good communication and a positive attitude
                  towards patients:
                  <br />
                  "Without patients, there would be no need for Doctors or
                  Nurses. A good interpersonal relationship with patients is
                  crucial. It's not just about technical skills, but also about
                  having a positive attitude towards those we care for. Everyone
                  in the institution, from doctors to support staff, plays a
                  role in patient safety."
                  <br />
                  <br />
                  A patient from the Pediatrics Outpatient Department,
                  Miss Alaba Iseoluwa, shared her positive experience with LUTH:
                  <br />
                  "The staff here, from Doctors to Nurses, have been very
                  welcoming and kind. They ensure the Patients are well-informed
                  and comfortable throughout their treatment. I believe LUTH
                  will continue to improve with further government support,
                  better facilities, and more training for healthcare
                  professionals." 
                  <br />
                  The event was a resounding success, serving as
                  a reminder of the pivotal role every healthcare worker plays
                  in ensuring patient safety. LUTH continues to strive for
                  excellence by prioritizing patient well-being and fostering a
                  culture of safety across all departments. 
                  <br />
                  Also Present, is the
                  Representative of the Director of Administration. The Chief
                  Medical Director also used the opportunity to appreciate the
                  Office of the Chief Medical Advisory Committee (CMAC) who
                  coordinated the programme.
                </p>
                <div className='row'>
                  <div className='col-lg-12'>
                    <img src={blogd4} height={370} />
                  </div>
                </div>
                {/* <span style={{ fontSize: 14, fontStyle: 'italic' }}>
                  Baale of Mashewele community, community elder, HOD of
                  Community Health Dept Prof Ogunnowo, Dr Roberts ,Mushin LGA
                  Health Educator Mrs Akinterinwa and Residents in Community
                  Health.
                </span> */}
                {/* <p className='blog-one__text' style={{ marginBottom: 30 }}>
                  Other pictures as found below:
                </p> */}
                <div className='row'>
                  <div className='col-lg-6'>
                    <img src={blogd5} height={370} />
                  </div>
                  <div className='col-lg-6'>
                    <img src={blogd6} height={370} />
                  </div>
                  <div className='col-lg-6'>
                    <img src={blogd8} height={370} />
                  </div>
                  <div className='col-lg-6'>
                    <img src={blogd7} height={370} />
                  </div>
                  {/* <div className='col-lg-6'>
                    <img src={blogd7} height={370} />
                  </div> */}
                  {/* <div className='col-lg-6'>
                    <img src={blogd10} height={370} />
                  </div> */}
                </div>
                {/* <span style={{ fontSize: 14, fontStyle: 'italic' }}>
                  Fig 1: Residents at the department before the program
                  <br />
                  Fig 2: Dr Roberts and Dr Balogun Consultants at Dept of Comm
                  Health at the Outreach.
                </span> */}
                <div className='row'>
                  <div className='col-lg-12'>
                    <img src={blogd9} height={370} />
                  </div>
                </div>
                {/* <span style={{ fontSize: 14, fontStyle: 'italic' }}>
                  Residents dispensing medications in advance for the outreach
                </span> */}
              </div>
            </div>
            <div className='share-block'>
              <div className='left-block'>
                <p>
                  Tags: <a href='#none'>Community Health Department</a>
                </p>
              </div>
              {/* <div className='social-block'>
              <a href='#none'>
                <i className='fab fa-twitter'></i>
              </a>
              <a href='#none'>
                <i className='fab fa-facebook-f'></i>
              </a>
              <a href='#none'>
                <i className='fab fa-instagram'></i>
              </a>
              <a href='#none'>
                <i className='fab fa-dribbble'></i>
              </a>
            </div> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

const GalleryPage = () => {
  return (
    <Layout pageTitle='Medical Outreach to Mashewele Community  | LUTH News'>
      <NavOne />
      <PageHeader title='' />
      <NewsDetails />
      <Footer />
    </Layout>
  );
};

export default GalleryPage;
